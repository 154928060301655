import store from '@/store';
import moment from 'moment';

function mapTasks(tasks) {
  return tasks.reduce(
    (data, task) => {
      return {
        tasks: [...data.tasks, mapTaskToGantt(task, tasks)],
        links: [...data.links, ...mapToLinks(task.relations)],
      };
    },
    {
      tasks: [],
      links: [],
    }
  );
}

function mapProjects(projects) {
  return projects.reduce(
    (data, project) => {
      return {
        tasks: [...data.tasks, mapProjectToGantt(project)],
      };
    },
    {
      tasks: [],
    }
  );
}

function getTaskProgressbarColor(task) {
  const { labels } = task;

  if (labels?.length) return labels[0].color;
  if (!task?.parent && !task?.parents?.id) {
    return store.getters.selectedLicense.theme;
  }
}

function getTaskPredecessors(task, tasks = []) {
  const predecessorIds = tasks
    .flatMap((t) => t.relations)
    .filter((r) => r.task === task.id)
    .map((r) => r.previous_task);

  if (predecessorIds.length === 0) return '-';

  let text = '';
  predecessorIds.forEach((p, index) => {
    text += `#${tasks.find((t) => t.id === p).number}${
      index < predecessorIds.length - 1 ? ', ' : ''
    }`;
  });
  return text;
}

function getTaskSuccessors(task, tasks = []) {
  const successorIds = tasks
    .flatMap((t) => t.relations)
    .filter((r) => r.previous_task === task.id)
    .map((r) => r.task);

  if (successorIds.length === 0) return '-';

  let text = '';
  successorIds.forEach((p, index) => {
    text += `#${tasks.find((t) => t.id === p).number}${
      index < successorIds.length - 1 ? ', ' : ''
    }`;
  });
  return text;
}

function mapTaskToGantt(task, tasks) {
  let item = {
    ...(task.id ? { id: task.id } : {}),
    title: task.title,
    text: task.title,
    status: task.status,
    color: getTaskProgressbarColor(task),
    description: task.description,
    predecessors: getTaskPredecessors(task, tasks),
    successors: getTaskSuccessors(task, tasks),
    party: task.labels[0]?.title ?? '-',
    number: task.number,
    ...(task.parents ? { parent: task.parents.id } : {}),
    ...(task.parent ? { parent: task.parent } : {}),
    ...(task.planned_start || task.created_at
      ? { start_date: (task.planned_start || task.created_at).slice(0, 10) }
      : {}),
    ...(task.planned_end || task.due
      ? { end_date: (task.planned_end || task.due).slice(0, 10) }
      : {}),
    ...(task.due ? { due: task.due.slice(0, 10) } : {}),
  };

  if (task.hasChildren) {
    item.progress = (
      task.children.filter((t) => t.status === 'closed')?.length /
      task.children.length
    ).toFixed(2);
  }
  return item;
}

function mapProjectToGantt(project) {
  return {
    ...(project.id ? { id: project.id } : {}),
    text: '',
    title: project.name,
    description: project.description,
    number: project.number,
    ...(project?.start_date || project.created_at
      ? {
          start_date: (project?.start_date || project.created_at)?.slice(0, 10),
        }
      : {}),
    end_date: project?.end_date?.slice(0, 10) || moment(Date.now()).format(),
  };
}

/**
 base operations with links: https://docs.dhtmlx.com/gantt/desktop__crud_dependency.html
 link type: https://docs.dhtmlx.com/gantt/api__gantt_links_config.html
 @return: {"id":"1", "source":"1", "target":"2", "type":"1"}[]
 */
function mapToLinks(links, type = 0) {
  return (links || []).map((link) => {
    return {
      id: link.id,
      source: link.source_id,
      target: link.target_id,
      type,
    };
  });
}

export {
  mapTasks,
  mapTaskToGantt,
  mapProjects,
  mapProjectToGantt,
  getTaskProgressbarColor,
  getTaskPredecessors,
  getTaskSuccessors,
  mapToLinks,
};
